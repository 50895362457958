import { IconProps } from '@/components/Icon';
import { ImageProps } from '@/components/Image';
import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import mergeProps from 'lib/utils/mergeProps';
import { ReactNode } from 'react';
import { AuthorsList, AuthorsListProps } from './AuthorsList';
import { AuthorsListDescriptionProps } from './AuthorsList.Description';
import { AuthorsListGroupProps } from './AuthorsList.Group';
import { AuthorsListHeadlineProps } from './AuthorsList.Headline';
import { AuthorsListItemProps } from './AuthorsList.Item';
import { AuthorsListItemIconProps } from './AuthorsList.Item.Icon';
import { AuthorsListItemImageProps } from './AuthorsList.Item.Image';
import { AuthorsListItemTitleProps } from './AuthorsList.Item.Title';

export interface StandaloneAuthorsListAuthor extends StandaloneComponentProps {
  icon?: IconProps;
  image?: ImageProps;
  link?: LinkProps;
  title?: ReactNode;
}

export interface StandaloneAuthorsListProps extends StandaloneComponentProps {
  authors?: StandaloneAuthorsListAuthor[];
  description?: ReactNode;
  fallback?: string;
  headline?: ReactNode;
  options?: AuthorsListProps & {
    $description?: AuthorsListDescriptionProps;
    $group?: AuthorsListGroupProps;
    $headline?: AuthorsListHeadlineProps;
    $item?: AuthorsListItemProps & {
      $icon?: AuthorsListItemIconProps['$standalone']['options'];
      $image?: AuthorsListItemImageProps['$standalone']['options'];
      $title?: AuthorsListItemTitleProps;
    };
    $link?: LinkProps['options'];
  };
}

export const StandaloneAuthorsList: StandaloneComponent<StandaloneAuthorsListProps> = ({
  authors,
  description,
  fallback,
  headline,
  options,
  ...props
}) => {
  const {
    $description: descriptionProps,
    $group: groupProps,
    $headline: headlineProps,
    $item,
    $link: linkProps,
    ...baseProps
  } = options ?? {};

  const { $icon: $itemIcon, $image: $itemImage, $title: itemTitleProps, ...itemProps } = $item ?? {};

  return (
    <AuthorsList {...baseProps} {...props}>
      {headline && <AuthorsList.Headline {...headlineProps}>{headline}</AuthorsList.Headline>}

      {description && <AuthorsList.Description {...descriptionProps}>{description}</AuthorsList.Description>}

      {authors && authors.length > 0 && (
        <AuthorsList.Group {...groupProps}>
          {authors.map((author, index) => {
            const link = mergeProps({ options: linkProps }, author.link);

            return (
              <Link
                key={index}
                {...link}
                content={
                  <AuthorsList.Item {...itemProps}>
                    {author.image && (
                      <AuthorsList.Item.Image
                        $standalone={{
                          options: {
                            ...$itemImage,
                            blurDataURL: fallback,
                          },
                          ...author.image,
                        }}
                      />
                    )}

                    {author.title && (
                      <AuthorsList.Item.Title {...itemTitleProps}>{author.title}</AuthorsList.Item.Title>
                    )}

                    {author.icon && <AuthorsList.Item.Icon $standalone={{ options: $itemIcon, ...author.icon }} />}
                  </AuthorsList.Item>
                }
              />
            );
          })}
        </AuthorsList.Group>
      )}
    </AuthorsList>
  );
};
