import { Image, ImageProps } from '@/components/Image';
import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import getUrl from '@/utils/getUrl';
import { cn } from 'lib/utils';

const defaultFallback = getUrl(
  `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`,
)?.href;

export interface AuthorsListItemImageProps extends ExtendedStandaloneComponentProps<ImageProps> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const AuthorsListItemImageComponent: ExtendedStandaloneComponent<AuthorsListItemImageProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, src, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, blurDataURL, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('item_image_colors', colors);
  const sizeClassName = propClassName('item_image_size', size);
  const variantClassName = propClassName('item_image_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Image
      src={src}
      options={{
        blurDataURL: blurDataURL ?? defaultFallback,
        className: componentClassName,
        $group: { className: 'w-auto' },
        ...standaloneOptions,
      }}
      {...standaloneProps}
      {...props}
    />
  );
};
