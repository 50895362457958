import { mergeProps } from '@/utils/merge';
import { StandaloneRecommendedArticles } from 'base/components/RecommendedArticles';

export const MabraStandaloneRecommendedArticles: typeof StandaloneRecommendedArticles = (props) => {
  return (
    <StandaloneRecommendedArticles
      {...mergeProps(
        {
          headline: 'Mer från MåBra',
          options: {
            $list: {
              $item: {
                $teaser: {
                  size: 'none',
                },
              },
            },
          },
        },
        props,
      )}
    />
  );
};
